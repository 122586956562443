import './home.scss';
import IconFacebook from './../../assets/svg/facebook.js';
import IconInstagram from './../../assets/svg/instagram.js';
import IconLinkedin from './../../assets/svg/linkedin.js';
import IconTwitter from './../../assets/svg/twitter.js';
import IconGithub from './../../assets/svg/github.js';
import IconYoutube from './../../assets/svg/youtube.js';
import IconPinterest from './../../assets/svg/pinterest.js';
import IconSkype from './../../assets/svg/skype.js';

import NumberTranslate from './../../functions/NumberTranslate';
import { withNamespaces } from 'react-i18next';
import i18n from './../../i18n';
function Home({ t }) {
  const IconLink = (param) => {
    return(
      <a className="icon-link" href={param.url} target={param.target?param.target:"_self"} rel="noopener noreferrer">
        <param.icon color="#202028"/>
      </a>
    )
  }
  return (
    <>
      <div className="content">
        <h2 className="t">{t('Vireak EK.')}</h2>
        {/* <p className="t">{NumberTranslate(new Date().getFullYear()-1996, i18n.language)} {t('years old.')}</p> */}
        {/* <p className="t">{t('Information Technology.')}</p> */}
        <p className="t">{t('Web Developer.')}</p>
        <p><a className="t" href="https://mediapark.com" target="_blank" rel="noopener noreferrer">{t('Mediapark.')}</a></p>
        <p className="t">{t('Cambodia.')}</p>
        <p><a href="mailto:sursdey@ekvireak.com">sursdey@ekvireak.com</a></p>
      </div>
      <div className="social-links">
        <IconLink icon={IconFacebook} url="https://facebook.com/ek96vireak/" target="_blank" />
        <IconLink icon={IconInstagram} url="https://instagram.com/ek96vireak/" target="_blank" />
        <IconLink icon={IconLinkedin} url="https://linkedin.com/in/vireak-ek-5aaaa814a/" target="_blank" />
        <IconLink icon={IconGithub} url="https://github.com/EkVireak/" target="_blank" />
        <IconLink icon={IconTwitter} url="https://twitter.com/ek_vireak" target="_blank" />
        <IconLink icon={IconSkype} url="https://join.skype.com/invite/pBvxpgU5aqdg" target="_blank" />
        <IconLink icon={IconPinterest} url="https://pinterest.com/ekvireak96/" target="_blank" />
        <IconLink icon={IconYoutube} url="https://youtube.com/channel/UCSl-4mPIP1JiwIvegU7qQtQ" target="_blank" />
      </div>
    </>
  );
}

export default withNamespaces()(Home);

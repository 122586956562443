import React, {useState} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import i18n from './i18n';
import { withNamespaces } from 'react-i18next';

import me from './assets/me.min.png';
import IconEN from './assets/en.png';
import IconKH from './assets/kh.png';

import Home from './components/home/home';
import Four0Four from './components/404/404';

function App({t}) {
  const [lang, setLang] = useState(i18n.language);
  const changeLanguage = (lng)=>{
    if(lng===lang) return;
    i18n.changeLanguage(lng);
    setLang(lng);
    var x;
    var cut = 0;
    if(lng==='en'){
      cut = 0;
      x = window.location.origin+'/'+lng+''+window.location.pathname.substr(cut, window.location.pathname.length);
    }
    else{
      cut = 3;
      x = window.location.origin+''+window.location.pathname.substr(cut, window.location.pathname.length);
    }
    window.history.pushState(lng, lng, x);
  }
  return (
    <Router>
      <div className={`app lang-${lang}`} style={{"backgroundImage":`url(${me})`}}>
        <div className="languages">
          <ul>
            <li>
              <a href="/" className={`link-lang ${lang==='kh'?'active':''}`} onClick={(e)=>{e.preventDefault();changeLanguage('kh');}}>
                <img src={IconKH} className="app-language" alt="flag" />
              </a>
            </li>
            <li>
            <a href="/" className={`link-lang ${lang==='en'?'active':''}`} onClick={(e)=>{e.preventDefault();changeLanguage('en');}}>
                <img src={IconEN} className="app-language" alt="flag" />
              </a>
            </li>
          </ul>
        </div>
        <div className="main">
          <div className="main-content">
            <Switch>
              {/* <Route path="/about">
                <About />
              </Route>
              <Route path="/users">
                <Users />
              </Route> */}
              <Route exact path={["/", "/en"]}>
                <Home />
              </Route>
              <Route path="*">
                <Four0Four/>
              </Route>
            </Switch>
          </div>      
        </div>
      </div>
      
    </Router>
  );
}

export default withNamespaces()(App);

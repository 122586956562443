function IconSkype(param) {
    return (
        <svg style={{enableBackground:"new 0 0 64 64"}} version="1.1" viewBox="0 0 64 64" space="preserve" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <g id="Layer_2" fill={param.color}>
                <path d="M7.5,32L7.5,32c0,13.5,10.9,24.4,24.3,24.4c1.7,0,3.4-0.2,5-0.5c3.2-0.7,6.6-1.1,9.9-0.8   c0.8,0.1,1.7,0.1,2.5,0c5.5-0.6,10-5.2,10.5-10.7c0.3-3-0.6-5.8-2.2-8.1c-0.9-1.2-1.3-2.7-1.3-4.2c0,0,0,0,0,0   c0-13.4-10.9-24.3-24.3-24.3c-1.7,0-3.4,0.2-5,0.5c-3.2,0.7-6.5,1.1-9.8,0.8c-0.8-0.1-1.7-0.1-2.6,0C9.1,9.6,4.7,14,4.1,19.3   c-0.4,3.1,0.5,6,2.1,8.2C7,28.9,7.5,30.4,7.5,32z M19.4,37.3c1.6-1.3,3.9-1,5.1,0.6c0,0,2.9,3.4,7,3.5c5,0.2,6.1-1.3,6.2-2.6   c0.2-1.5-1.2-2.7-6.7-4c-4.7-1.1-11.7-2.8-11.7-10.3c0-2.3,1-10,13.2-10c7.7,0,12.3,6.1,12.5,6.4c1.2,1.6,0.8,3.9-0.8,5.1   c-1.6,1.2-3.9,0.9-5.1-0.8c-0.1-0.1-2.7-3-6.6-3.4c-3-0.3-5.9,0.8-5.9,2.4c0,1.7,0.8,2.2,6.1,3.5c4.9,1.2,12.3,2.9,12.3,11.1   c0,4-2,7.1-5.6,8.6c-2.6,1.1-5.5,1.3-8,1.3c-7.7,0-12.6-6-12.8-6.2C17.5,40.8,17.8,38.5,19.4,37.3z"/>
            </g>
        </svg>
    );
}

export default IconSkype;
import './404.scss';
import NumberTranslate from './../../functions/NumberTranslate';
import { withNamespaces } from 'react-i18next';
import i18n from './../../i18n';
import { Helmet } from "react-helmet";
function Four0Four({t}) {
  return (
    <>
      <Helmet>
        <title>{`${NumberTranslate(404, i18n.language)}`}</title>
      </Helmet>
      <div className="content">
        <h2 className="t">{NumberTranslate(404, i18n.language)}</h2>
        <p className="t">{t('Page not found!')}</p>
        <a className="t" href={`/${i18n.language==='kh'?'':i18n.language}`}>{t('Back to Home.')}</a>
      </div>
    </>
  );
}

export default withNamespaces()(Four0Four);
